.formContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input {
  flex: 2;
  font-weight: 200;
  font-size: 30px;
  padding: 11px;
  border-radius: 0.3rem;
  border: transparent;
}

button, a.cta {
  flex: 1;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 60px;
  padding: 60px 80px;
  border-radius: 8px;
  background-color: rgb(0, 212, 255);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  width: 90%;
  display: inline-block;
  margin: 0 0 2rem 0;
  text-decoration: none;

}

button:hover, a.cta:hover {
  background-color: rgb(0, 179, 215);
}

button:active, a.cta:active {
  background-color: rgb(21, 128, 149);
}

.error {
  width: 100%;
  margin-top: 24px;
  color: #222;
  text-align: center;
  font-size: 21px;
}

.success {
  width: 100%;
  margin-top: 42px;
  margin-bottom: 16px;
  color: #fff;
  text-align: center;
  font-size: 21px;
}

@media screen and (max-width: 600px) {
  .formContainer {
    width: 100%;
    display: block;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  input {
    width: 100%;
    font-size: 18px;
  }

  button, a.cta {
    width: 100%;
    padding: 2rem 0;
    font-size: 36px;
  }
}
