h1.tuze_highlight {
  color: #f2f2f2;
  text-shadow: 0px 2px 3px rgba(51, 51, 51, 0.37);
}

h3 {
  margin: 3rem 0;
}

h4 {
  font-size: 2.5rem;
  color: #f2f2f2;
  font-weight: 900;
  margin: 0 0 1em 0;
  text-shadow: 0px 2px 3px rgba(51, 51, 51, 0.37);
  text-transform: uppercase;
}

h5 {
  font-size: 23px;
  color: #222;
  margin: 0;
}

header {
  max-width: 49em;
  text-align: center;
  margin: 0 auto 0 auto;
}

p.nav-link {
  margin: 1em auto 1em auto;
  width: 41.5em;
  padding: 2em;
}

code {
  font-family: Saira, sans-serif;
  color: #f2f2f2;
  padding: 2.4rem;
  display: block;
  text-align: left;
  line-height: 135%;
  background-color: #222;
  border-radius: 5px;
}

code > a {
  font-family: Saira, sans-serif;
  color: #f2f2f2;
}

section {
  /* background-color: rgba(255, 255, 255, 0.45); */
  margin: 0 auto 0 auto;
  max-width: 49em;
  /* box-shadow: 0px 10px 15px -9px rgba(51, 51, 51, 0.2); */
  border-radius: 5px;
  text-align: center;
}

p,
ul > li {
  font-size: 21px;
  font-weight: 400;
  line-height: 125%;
  margin: 0 0 1em 0;
}

footer {
  color: #999;
  margin: 0 auto 5em auto;
  text-align: center;
}

footer > a {
  color: #f2f2f2;
  text-decoration: none;
}

footer > p {
  font-size: 1em;
  color: #999;
  margin-top: .3em;
}

@media screen and (min-width: 601px) {
  h1 {
    font-weight: 900;
    font-size: 62px;
    letter-spacing: -0.05em;
    line-height: 105%;
  }

  h3 {
    font-weight: 700;
    font-size: 36px;
    letter-spacing: -0.05em;
    line-height: 100%;
    color: #f2f2f2;
  }

  img.tuze_icon {
    max-width: 10em;
    display: inline-block;
    margin: 2em 0 3em;
  }

  img.shot {
    float: right;
    max-height: 710px;
    margin-left: 3em;
  }

  img.albert-inset {
    float: left;
    border-radius: 10px;
    width: 14rem;
    margin: 0 1rem 1rem 0;
  }

}

.emailFormContainer {
  position: relative;
}

@media screen and (max-width: 600px) {
  h1 {
    margin: 1em 0.5em;
    font-weight: 900;
    font-size: 48px;
    letter-spacing: -0.05em;
    line-height: 95%;
  }

  h3 {
    margin: 1em 0.5em;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: -0.05em;
    line-height: 100%;
    color: #f2f2f2;
  }

  h4 {
    font-size: 1.8rem;
    text-align: center;
  }

  img.tuze_icon {
    max-width: 5em;
    display: inline-block;
    margin: 2em 0 3em;
  }

  img.shot {
    max-height: 557px;
    margin: 0 auto 2em auto;
    display: block;
  }

  section {
    margin: 1em 0.5em 0 0.5em;
  }

  div.zoomImages {
    display: block;
  }

  img.albert-inset {
    float: left;
    border-radius: 10px;
    width: 7rem;
    margin: 0 1rem 1rem 0;
  }
}
